<template>
  <div class="min-w-screen min-h-screen w-full h-full bg-repeat pb-20" :style="{'background-image': `url(${require('./assets/random_grey_variations.png')})`}" >
    <div class="relative z-20">
    
      <router-view/>
      
    </div>
    
    
    <!-- <FooterBottom/> -->
    <div class="fixed z-10 bottom-0 w-full">
      <FooterBottom/>
    </div>
    
  </div>
  
  <!-- Client data popup -->
  <div class="fixed bottom-0 w-full z-30 px-4 py-2 text-center sm:text-left" v-if="cookiesPopup">
    <div class="flex max-w-screen-md justify-center mx-auto border border-white rounded border-gray-800 shadow shadow-black/80 bg-gray-100 text-center text-gray-800 py-2 px-4">
      <div>
        <span>Δεν χρησιμοποιούμε cookies για να την πλοήγησή σας σε αυτή την ιστοσελίδα.
          <router-link to="/privacy-policy" class="block cursor-pointer ml-2 hover:text-cyan-500 hover:underline"><b>Διαβάστε περισσότερα</b></router-link>
        </span>
      </div>
      <!-- Close button -->
      <div class="flex flex-row justify-start pl-4">
        <span class="sr-only">Κλείσιμο</span>
        <svg aria-hidden="true" class="cursor-pointer w-5 h-5 hover:text-cyan-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"  @click="cookiesPopupOff()"></path>
        </svg>
      </div>
    </div>
  </div>
  
  <div id="Toasts" class="relative z-[70]">  
    <transition appear
            enter-active-class="duration-[300ms] ease-out"
            enter-from-class="transition opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="duration-[300ms] ease-in"
            leave-from-class="opacity-100"
            leave-to-class="transition opacity-0"
            >
        <div v-if="$store.state.toastToggleBool == true">
            <Toasts :message="$store.state.message"
                    :type="$store.state.type"
                    :toastToggleBool="$store.state.toastToggleBool"
                    :key="$store.state.toastKey"/>
        </div>
    
    </transition>
  </div>
  
</template>


<style lang="css">
@import "../public/tailwind.css";
</style>

<script>
import FooterBottom from '@/components/FooterBottom.vue'
import Toasts from '@/components/Toasts.vue'

export default {
  components: {
    FooterBottom,
    Toasts
  },
  data() {
    return {
      cookiesPopup: true,
    }
  },
  beforeCreate() {
    this.$store.dispatch('initializeStoreCheckToken')
  },
  mounted() {
    this.cookiesPopup = this.$store.state.cookiesPopup
  },
  methods: {
    cookiesPopupOff() {
      this.$store.state.cookiesPopup = false
      this.cookiesPopup = this.$store.state.cookiesPopup
      this.$store.commit('saveCookiesPopupState')
    },
  },
}
</script>
