<template>

    <section class="flex justify-center items-start bg-w-[4px]">
        <transition appear
          enter-active-class="duration-[2000ms] ease-out"
          enter-from-class="transition opacity-0"
          enter-to-class="opacity-100"
          leave-active-class="duration-[2000ms] ease-in"
          leave-from-class="opacity-100"
          leave-to-class="transition opacity-0"
        >
            <div class="max-w-[56rem] flex flex-col justify-center items-center sm:mx-2 mt-10 sm:mt-20 px-6 sm:px-14 py-14 sm:mx-6 border-2 border-gray-800 shadow-inner shadow-black/80 bg-gray-100 text-justify text-[#404650]">
                <h2 class="text-center mb-10">
                    Σχετικά με την υπηρεσία
                </h2>
                <!-- Navigation Bar -->
                <NavBar3/>
                
                <p class="mb-4">
                  Σε αυτή τη σελίδα βρίσκονται πληροφορίες σχετικές με αυτήν την ψηφιακή υπηρεσία. Εάν κάποια πληροφορία είναι ελλιπής παρακαλώ επικοινωνήστε μαζί μας εδώ <a class="text-[#12899D] hover:underline" :href="'mailto:support@starfields.gr?&subject=Email για τη σελίδα Σχετικά του keys.' + $store.state.domain_name">support@starfields.gr</a>.
                </p>
                <h3 class="text-center mb-4">
                  Σχετικά με την ψηφιακή υπηρεσία
                </h3>
                <p class="mb-4">
                  Αυτή η ιστοσελίδα προσφέρει έναν εύκολο τρόπο στους χρήστες να κάνουν χρήση των υπηρεσιών Web Key Directory (WKD) που προσφέρουν πάροχοι email για την εύρεση κρυπτογραφικών κλειδιών που σχετίζονται με διευθύνσεις ηλεκτρονικού ταχυδρομείου.
                </p>
                
                <p class="mb-4"> 
                  Η σελίδα σας επιτρέπει να:
                  <ul class="ml-5 mb-4"> 
                    <li class="list-disc">
                      βρείτε κρυπτογραφικά κλειδιά που σχετίζονται με διευθύνσεις ηλεκτρονικού ταχυδρομείου που ανήκουν στον πάροχο που επισκέπτεστε.
                    </li>
                    <li class="list-disc">
                      βρείτε κρυπτογραφικά κλειδιά που σχετίζονται με διευθύνσεις ηλεκτρονικού ταχυδρομείου που <i>δεν</i> ανήκουν στον πάροχο που επισκέπτεστε.
                    </li>
                  </ul>
                </p>
                <p>
                  Αυτό σημαίνει ότι εάν ψάχνετε για το κλειδί του <i>example@maildomain.com</i>, εφόσον ο πάροχος email, σε αυτήν την περίπτωση το maildomain.com, του email που ψάχνετε προσφέρει υπηρεσίες Web Key Directory δημόσια, αυτή η σελίδα μέσο της αναζήτησής σας θα βρει το κλειδί που ψάχνετε.
                </p>
            </div>
        </transition>
    </section>
    
</template>

<script>
import axios from 'axios'
import NavBar3 from '@/components/NavBar3.vue'

export default {
  name: 'AboutUs',
  components: {
    NavBar3,
  },
  mounted() {
    document.title = ' Σχετικά με την υπηρεσία'
    window.scrollTo(0,0)
  },
}
</script>


