<template>
    <!-- Main section -->
    <section class="flex flex-row justify-center items-start bg-w-[4px]">
        <transition appear
          enter-active-class="duration-[2000ms] ease-out"
          enter-from-class="transition opacity-0"
          enter-to-class="opacity-100"
          leave-active-class="duration-[2000ms] ease-in"
          leave-from-class="opacity-100"
          leave-to-class="transition opacity-0"
        >
            <div class="max-w-[56rem] sm:mx-6 mt-10 sm:mt-20 lg:mt-32 px-6 sm:px-14 py-14 border-2 border-gray-800 shadow-inner shadow-black/80 bg-gray-100 text-center text-[#404650]">
                <h2 class="mb-4 text-[#404650]">
                    Αναζήτηση Web Key Directory
                </h2>
                <p v-if="found_key" class="mb-1">
                Βρέθηκε το ακόλουθο κρυπτογραφικό κλειδί για {{searchedQuery}}.
                </p>
                <!-- Result box -->
                <div v-if="found_key" class="flex flex-row justify-center items-center mb-6 gap-1">
                  <div class="max-w-[23rem] md:w-full h-10 overflow-auto rounded border-2 border-gray-200 shadow-inner text-gray-800 bg-white">
                    <input @click="copyText()" :value="keyresult" id="key-box" readonly class="w-full pt-1 pl-4 select-all whitespace-nowrap outline-none focus:ring-0">
                  </div> 
                  <!-- Copy Button -->
                  <button @mouseover="copyButtonHovered = true" @mouseleave="copyButtonHovered = false" @click="copyText()" data-popover-target="popover-default" type="button" class="relative flex flex-row justify-center items-center rounded shadow border border-transparent text-center text-white font-medium bg-[#12899D] outline-none px-2 py-2 sm:border-[#12899D] sm:py-1.5 sm:px-4">
                    <div class="relative">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="x`w-5 h-5" viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M448 384H256c-35.3 0-64-28.7-64-64V64c0-35.3 28.7-64 64-64H396.1c12.7 0 24.9 5.1 33.9 14.1l67.9 67.9c9 9 14.1 21.2 14.1 33.9V320c0 35.3-28.7 64-64 64zM64 128h96v48H64c-8.8 0-16 7.2-16 16V448c0 8.8 7.2 16 16 16H256c8.8 0 16-7.2 16-16V416h48v32c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V192c0-35.3 28.7-64 64-64z"/></svg>
                    </div>
                    <span class="hidden sm:block pl-2">Αντιγραφή</span>
                    <!-- Copy Bubble -->
                    <div :class="copyButtonHovered ? 'opacity-100' : 'opacity-0'" role="tooltip" class="absolute -top-14 z-10 w-44 text-white transition-opacity duration-300 bg-[#525964] border-2 border-gray-700 rounded-lg shadow-sm">
                        <div class="px-3 py-2">
                            <p>Αντιγραφή του κλειδιού</p>
                        </div>
                        <div class="absolute -bottom-1.5 bg-[#525964] border-gray-700" style="border-width: 0 2px 2px 0; display: inline-block; padding: 3px; transform: rotate(45deg); -webkit-transform: rotate(45deg);">
                        </div>
                    </div>
                    <!-- Copied Bubble -->
                    <div :class="textCopied ? 'opacity-100' : 'opacity-0'" role="tooltip" class="absolute -top-14 z-20 w-44 text-white transition-opacity duration-300 bg-[#525964] border-2 border-gray-700 rounded-lg shadow-sm">
                        <div class="px-3 py-2">
                            <p>Αντιγράφηκε</p>
                        </div>
                        <div class="absolute -bottom-1.5 bg-[#525964] border-gray-700" style="border-width: 0 2px 2px 0; display: inline-block; padding: 3px; transform: rotate(45deg); -webkit-transform: rotate(45deg);">
                        </div>
                    </div>
                  </button>
                  <!-- Download Button -->
                  <a v-bind:href="this.download_url" v-bind:download="this.fileName" @mouseover="downloadButtonHovered = true" @mouseleave="downloadButtonHovered = false" data-popover-target="popover-default" type="button" class="relative flex flex-row justify-center items-center rounded shadow border border-transparent text-center text-white font-medium bg-[#12899D] outline-none px-2 py-2 sm:py-1.5 sm:px-4 sm:border-[#12899D]">
                    <div class="relative">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="x`w-5 h-5" viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zm368 56a24 24 0 1 1 0 48 24 24 0 1 1 0-48z"/>
                      </svg>
                    </div>
                    <span class="hidden sm:block pl-2">Κατέβασέ το</span>
                    <!-- Download Bubble -->
                    <div :class="downloadButtonHovered ? 'opacity-100' : 'opacity-0'" role="tooltip" class="absolute -top-14 z-10 w-44 text-white transition-opacity duration-300 bg-[#525964] border-2 border-gray-700 rounded-lg shadow-sm">
                        <div class="px-3 py-2">
                            <p>Πατήστε εδώ για να το κατεβάσετε</p>
                        </div>
                        <div class="absolute -bottom-1.5 bg-[#525964] border-gray-700" style="border-width: 0 2px 2px 0; display: inline-block; padding: 3px; transform: rotate(45deg); -webkit-transform: rotate(45deg);"></div>
                    </div>
                  </a>
                </div>
                <!-- Search box -->
                <div class="relative w-full sm:w-2/3 mx-auto flex justify-center items-center mb-4">
                  <input @keydown.enter="getWKD()" type="email" id="default-search" class="w-full rounded border-2 border-gray-200 shadow-inner text-left text-gray-800 text-sm bg-white outline-none p-2 pl-6 focus:border-[#12899D] focus:ring-1 focus:ring-[#12899D]" :placeholder="'example-user@' + $store.state.domain_name" v-model="query" required>
                  <button class="absolute right-0.5 top-0.5 flex flex-row justify-center items-center rounded-r-sm shadow text-center text-white font-medium bg-[#12899D] outline-none px-2 py-2 sm:py-1.5 sm:px-4 mb-2" v-on:click="getWKD">
                    <svg aria-hidden="true" class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
                    <span class="hidden sm:block pl-2">Αναζήτηση</span>
                  </button>
                </div>
                <div class="" v-if="error != ''">
                    <p class="w-fit mx-auto rounded border-2 border-red-400 bg-red-100 text-red-400 mb-4 px-4 py-2" v-bind:key="error">{{ error }}</p>
                </div>
                <p class="mb-4">Δεν είστε σίγουροι πώς να χρησιμοποιήσετε αυτήν την υπηρεσία; Διαβάστε τον οδηγό μας 
                <router-link to="/usage" class="text-[#12899D] hover:underline">εδώ</router-link>.
                </p>
                <p class="">
                  Εναλλακτικά μπορείτε να στείλετε email στον διαχειριστή 
                  <a class="text-[#12899D] hover:underline" :href="'mailto:' + $store.state.support_email + '?&subject=Προς τον διαχειριστή κλειδιών του ' + $store.state.domain_name">εδώ</a>.
                </p>
                <hr class="border-2 border-[#12899D] my-4">
                <p>
                Διαβάστε <router-link to="/about" class="text-[#12899D] hover:underline">εδώ</router-link> για αυτήν την υπηρεσία.
                </p>
                <!-- PAGES: main page with search function that triggers and axios request, about page which explains what exactly is going on, web key service HOW TO page -->
            </div>
        </transition>
    </section>
    
</template>

<script>
import axios from 'axios'
import {readKey} from 'openpgp'

export default {
  name: 'Home',
  data() {
    return {
      copyButtonHovered: false,
      downloadButtonHovered: false,
      textCopied: false,
      found_key: false,
      keyresult: '',
      query: '',
      download_url: '',
      fileName: '',
      error: '',
      searchedQuery: ''
    }
  },
  mounted() {
    document.title = 'Αναζήτηση Web Key Directory'
    let documentDescription = document.createElement('meta')
    documentDescription.setAttribute('name','description')
    documentDescription.setAttribute('content','Αυτή η ιστοσελίδα προσφέρει έναν εύκολο τρόπο στους χρήστες να κάνουν χρήση των υπηρεσιών Web Key Directory (WKD) που προσφέρουν πάροχοι email για την εύρεση κρυπτογραφικών κλειδιών που σχετίζονται με διευθύνσεις ηλεκτρονικού ταχυδρομείου.')
    document.head.appendChild(documentDescription);
    
    window.scrollTo(0,0)
  },
  
  methods:{
    encodeZBase32(data) {
      if (data.length === 0) {
        return "";
      }
      const ALPHABET = "ybndrfg8ejkmcpqxot1uwisza345h769";
      const SHIFT = 5;
      const MASK = 31;
      let buffer = data[0];
      let index = 1;
      let bitsLeft = 8;
      let result = '';
      while (bitsLeft > 0 || index < data.length) {
        if (bitsLeft < SHIFT) {
          if (index < data.length) {
            buffer <<= 8;
            buffer |= data[index++] & 0xff;
            bitsLeft += 8;
          } else {
            const pad = SHIFT - bitsLeft;
            buffer <<= pad;
            bitsLeft += pad;
          }
        }
        bitsLeft -= SHIFT;
        result += ALPHABET[MASK & (buffer >> bitsLeft)];
      }
      return result;
    },
    async getWKD() {
      // TODO validate that its a proper email address, make all the userid stuff lowercase
      // this.query
      this.error = ''
      this.searchedQuery = this.query
      const [localPart, domain] = this.query.split('@');
      const localPartEncoded = new TextEncoder().encode(localPart.toLowerCase());
      const localPartHashed = new Uint8Array(await crypto.subtle.digest('SHA-1', localPartEncoded));
      const localPartBase32 = this.encodeZBase32(localPartHashed);
      const localPartEscaped = encodeURIComponent(localPart);
      
      const urlDirect = `https://${domain}/.well-known/openpgpkey/hu/${localPartBase32}?l=${localPartEscaped}`;
      const urlAdvanced = `https://openpgpkey.${domain}/.well-known/openpgpkey/${domain}/hu/${localPartBase32}?l=${localPartEscaped}`;
      
      let response;
      this.found_key = false;
      try {
        response = await fetch(urlDirect);
        if (response.status == 200) {
          this.found_key = true
        } else {
          response = await fetch(urlAdvanced);
          if (response.status == 200) {
            this.found_key = true
          }
        }
      } catch(err) {
      }
      if (this.found_key == false) {
        this.error = 'No key found for ' + this.searchedQuery + ' in the public Web Key Directory server'
      } else { 
        const publicKeyBytes = new Int8Array(await response.arrayBuffer());
        
        // Section that decodes and assigns the key string
        this.keyresult = await readKey({
          binaryKey: new Uint8Array(publicKeyBytes)
        })
        this.keyresult = this.keyresult.armor()
        
        // Section that implements the download functionality
        const blob = new Blob([this.keyresult], {type: 'octet-stream'});
        window.URL.revokeObjectURL(this.download_url);
        this.download_url = window.URL.createObjectURL(blob);
        const contentDisposition = response.headers['content-disposition'];
        this.fileName = this.query.replace('@','-').replace('.','-')+'-key.asc';
        if (contentDisposition) {
            const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
            if (fileNameMatch.length === 2)
                this.fileName = fileNameMatch[1];
        }
        
        /* NOTE this block is important if you want to handle proper cryptographic operations, need to import openpgp
        this.keyresult = await readKey({
          binaryKey: publicKeyBytes
        });
        console.log(this.keyresult)
        */
      }
    },
    copyText() {
            const resultkey = document.getElementById("key-box");
            //resultkey.focus();
            resultkey.select();
            document.execCommand('copy');
            this.textCopied = true
            setTimeout(()=>
                (this.textCopied = false), 2000)
    }
  }
}
</script>


