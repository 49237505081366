<template>

    <section class="flex justify-center items-start bg-w-[4px]">
        <transition appear
          enter-active-class="duration-[2000ms] ease-out"
          enter-from-class="transition opacity-0"
          enter-to-class="opacity-100"
          leave-active-class="duration-[2000ms] ease-in"
          leave-from-class="opacity-100"
          leave-to-class="transition opacity-0"
        >
            <div class="max-w-[56rem] flex flex-col justify-center items-center sm:mx-2 mt-10 sm:mt-20 px-6 sm:px-14 py-14 sm:mx-6 border-2 border-gray-800 shadow-inner shadow-black/80 bg-gray-100 text-justify text-[#404650]">
                <h2 class="text-center mb-10">
                    Οδηγός χρήσης του <router-link to="/" class="hover:underline">Web Key Directory</router-link>
                </h2>
                
                <!-- Navigation Bar -->
                <NavBar3/>
                
                <p class="mb-4">
                  Σε αυτή τη σελίδα συλλέγουμε πληροφορίες σχετικά με τον τρόπο χρήσης αυτής της διαδικτυακής υπηρεσίας και παρέχουμε συνδέσμους σε επίσημους οδηγούς σχετικά με τον τρόπο εισαγωγής των κλειδιών κρυπτογράφησης σε διαφορετικά προγράμματα. Εάν δεν βρείτε οδηγίες για το αγαπημένο σας λογισμικό εδώ, παρακαλώ <a class="text-[#12899D] hover:underline" :href="'mailto:support@starfields.gr?&subject=Email για τη σελίδα Χρήση του keys.' + $store.state.domain_name">επικοινωνήστε μαζί μας</a>.
                </p>
                <h3 class="text-center mb-4">
                  Οδηγίες χρήσης της πλατφόρμας εύρεσης ηλεκτρονικών κλειδιών
                </h3>
                
                <h6 class="w-full text-left mb-2">
                  Πώς μπορώ να εισάγω το κλειδί που βρήκα για μια διεύθυνση email;
                </h6>
                <p class="mb-4">
                  Οι χρήστες ηλεκτρονικού ταχυδρομείου και άλλων λογισμικών έχουν διάφορους τρόπους εισαγωγής κλειδιών στο μπρελόκ τους (τη συλλογή αποδεκτών κλειδιών). Πολλά από αυτά θέλουν να τα εισαγάγετε με τη μορφή ενός .asc αρχείου. Η διαδικτυακή αυτή πλατφόρμα σας προσφέρει να κάνετε λήψη του κλειδιού σε τέτοιο αρχείο. Υπάρχει επίσης η δυνατότητα να αντιγράψετε το κλειδί στο πρόχειρο και να το εισαγάγετε με αυτόν τον τρόπο. Οδηγίες για το <i>Thunderbird</i> μπορούν να βρεθούν <a href="https://support.mozilla.org/en-US/kb/openpgp-thunderbird-howto-and-faq" rel="noreferrer noopener" target="_blank" class="inline text-[#12899D] hover:underline">εδώ</a>. Μπορείτε να δείτε πώς να διαχειριστείτε τα κλειδιά σας στο <i>KMail</i> <a href="https://docs.kde.org/stable5/en/kmail/kmail2/using-kmail.html" rel="noreferrer noopener" target="_blank" class="inline text-[#12899D] hover:underline">εδώ</a>. Για το <i>Evolution</i> μπορείτε να δείτε τον οδηγό <a href="https://help.gnome.org/users/evolution/stable/mail-encryption.html.en" rel="noreferrer noopener" target="_blank" class="inline text-[#12899D] hover:underline">εδώ</a>.
                </p>
                
                <h6 class="w-full text-left mb-2">
                  Πώς μπορώ να ανεβάσω ή να διαγράψω ένα κλειδί από τον διακομιστή κλειδιών του {{ $store.state.domain_name }};
                </h6>
                <p class="mb-4">
                  Εάν ο πάροχος email σας είναι το {{ $store.state.domain_name }}, για να ανεβάσετε ή να διαγράψετε ένα κλειδί από τον διακομιστή κλειδιών του {{ $store.state.domain_name }} μπορείτε να στείλετε email στον διαχειριστή <a class="text-[#12899D] hover:underline" :href="'mailto:' + $store.state.support_email + '?&subject=Email προς ' + $store.state.domain_name">εδώ</a>. Ένας άλλος τρόπος είναι να χρησιμοποιήσετε τo Web Key Service, to οποίo λειτουργεί στέλνοντας ορισμένα μηνύματα ηλεκτρονικού ταχυδρομείου σε μια συγκεκριμένη διεύθυνση. Μπορείτε να διαβάσετε περισσότερα <a href="https://wiki.gnupg.org/WKS" rel="noreferrer noopener" target="_blank" class="inline text-[#12899D] hover:underline">εδώ</a>.
                  </p>
                  <p>
                  Εάν ο πάροχος email σας <i>δεν</i> είναι το {{ $store.state.domain_name }}, τοτε μπορείτε να επικοινωνήσετε με τον πάροχο ηλεκτρονικού ταχυδρομείου σας και να τον ρωτήσετε εάν προσφέρουν υπηρεσίες Web Key Directory μαζί με οδηγίες σχετικά με τον τρόπο χρήσης τους.
                </p>
                
            </div>
        </transition>
    </section>
    
</template>

<script>
import axios from 'axios'
import NavBar3 from '@/components/NavBar3.vue'

export default {
  name: 'Usage',
  components: {
    NavBar3
  },
  mounted() {
    document.title = 'Σχετικά με την υπηρεσία'
    window.scrollTo(0,0)
  },
}
</script>


