<template>

    <!-- Latest Projects section -->
    <section class="flex justify-center items-start bg-w-[4px]">
        <transition appear
          enter-active-class="duration-[2000ms] ease-out"
          enter-from-class="transition opacity-0"
          enter-to-class="opacity-100"
          leave-active-class="duration-[2000ms] ease-in"
          leave-from-class="opacity-100"
          leave-to-class="transition opacity-0"
        >
            <div class="max-w-[56rem] flex flex-col justify-center items-center sm:mx-2 mt-10 sm:mt-20 px-6 sm:px-14 py-14 sm:mx-6 border-2 border-gray-800 shadow-inner shadow-black/80 bg-gray-100 text-justify text-[#404650]">
                <h2 class="text-center mb-10">
                    Συχνές Ερωτήσεις
                </h2>
                
                <!-- Navigation Bar -->
                <NavBar3/>
                
                <p class="mb-4">
                  Εδώ θα βρείτε κάποιες από τις συχνές ερωτήσεις για αυτή την υπηρεσία. Σας παρακαλούμε να μας ενημερώσετε εάν δεν μπορείτε να βρείτε την απάντηση σε κάποια ερώτησή σας <a class="text-[#12899D] hover:underline" :href="'mailto:support@starfields.gr?&subject=Email from Web Key Directory interface of ' + $store.state.domain_name + ' FAQ page'">εδώ</a>.
                </p>
                <h3 class="text-center mb-4">
                  Συχνές Ερωτήσεις (FAQs)
                </h3>
                
                <h6 class="w-full text-left mb-2">
                  Διανέμετε υπογραφές τρίτων?
                </h6>
                <p class="mb-4">
                  Όχι, αυτή η υπηρεσία υπάρχει ως ένας απλός μηχανισμός εντοπισμού για καταχωρημένα κλειδιά σε διακομιστές Web Key Directory. Υπάρχουν άλλοι τρόποι διανομής κλειδιών και άλλων τύπων κλειδιών που θα μπορούσαν να χρησιμοποιηθούν, ωστόσο, η διανομή δημόσιων κλειδιών είναι ο στόχος αυτής της ιστοσελίδας.
                </p>
                
                <h6 class="w-full text-left mb-2">
                  Διανέμετε κλειδιά που έχουν ανακληθεί?
                </h6>
                <p class="mb-4">
                  Το πρότυπο RFC για το Web Key Directory δεν υλοποιεί συγκεκριμένες μεθόδους για τη διανομή κλειδιών που έχουν ανακληθεί. Επομένως, αυτή η λειτουργικότητα δεν υποστηρίζεται.
                </p>
                
            </div>
        </transition>
    </section>
    
</template>

<script>
import axios from 'axios'
import NavBar3 from '@/components/NavBar3.vue'

export default {
  name: 'Usage',
  components: {
    NavBar3
  },
  mounted() {
    document.title = 'Συχνές Ερωτήσεις'
    window.scrollTo(0,0)
  },
}
</script>


