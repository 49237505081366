<template>

  <section class="flex flex-row justify-center items-start bg-w-[4px]">
    <transition appear
      enter-active-class="duration-[2000ms] ease-out"
      enter-from-class="transition opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="duration-[2000ms] ease-in"
      leave-from-class="opacity-100"
      leave-to-class="transition opacity-0"
    >
        <div class="max-w-[56rem] flex flex-col justify-center items-center sm:mx-2 mt-10 sm:mt-20 px-6 sm:px-14 py-14 sm:mx-6 border-2 border-gray-800 shadow-inner shadow-black/80 bg-gray-100 text-center text-[#404650]">
          <div class="relative">
            <img :src="require('../assets/undraw_taken_re_yn20.svg')" class="relative mx-auto h-32 md:h-52 2xl:h-60 px-10" alt="StarFields Logo"/>
            <h2 class="text-4xl md:text-5xl mt-2 2xl:mt-4 w-full font-serif text-center text-[#12899D] bg-contain bg-no-repeat bg-center">
              Error 404
            </h2>
            
          </div>
          <h3 class="font-serif bg-contain bg-no-repeat bg-center mt-6 md:mt-8">
            Δυστυχώς δε βρέθηκε η σελίδα που ψάχνετε.
          </h3>
          <h2 class="font-serif bg-contain bg-no-repeat bg-center mt-6 md:mt-8">
            Μπορούμε όμως να βοηθήσουμε!
          </h2>
          <div class="flex flex-col justify-center">
            <h4 class="font-serif bg-contain bg-no-repeat bg-center mt-6 md:mt-8">
              Αν πατήσετε  
              <router-link to="/" class="text-[#12899D] hover:underline">εδώ</router-link>
              θα σας μεταφέρουμε πίσω στην αρχική σελίδα
            </h4>
            <h4 class="font-serif bg-contain bg-no-repeat bg-center mt-4 md:mt-6">
              Ή αν προτιμάτε μπορείτε να
              <a class="text-[#12899D] hover:underline" :href="'mailto:' + $store.state.support_email + '?&subject=Μήνυμα προς την ομάδα υποστήριξης του ' + $store.state.domain_name">επικοινωνήσετε μαζί μας</a>.
            </h4>
          </div>
        </div>
    </transition>
  </section>
  
  
  
</template>

<script>
import axios from 'axios'

export default {
  name: 'NotFound',
  mounted() {
    document.title = 'Δεν βρέθηκε'
    window.scrollTo(0,0)
  }
}
</script>


