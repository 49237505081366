<template>
  
  <div v-bind:class="isMenuOpen ? 'z-40' : 'z-20'">
    <!-- Ghost-backdrop -->
    <div v-bind:class="isMenuOpen ? '' : 'hidden'" class="lg:hidden -z-10 bg-transparent top-0 bottom-0 left-0 right-0 h-screen"
      @click="toggleMenu()">
    </div>
    <nav>
      <h3 class="flex flex-wrap justify-center items-center mb-4 gap-2">
        <!-- Home router link -->
        <router-link to="/" class="text-cyan-500 hover:underline">
          <span class="">Αρχική</span>
        </router-link>
        <span> | </span>
        <!-- About router link -->
        <router-link v-if="$route.name == 'About'" to="/about" class="about">
          <span class="">Σχετικά</span>
        </router-link>
        <router-link v-else to="/about" class="text-cyan-500 hover:underline">
          <span class="">Σχετικά</span>
        </router-link>
        <span> | </span>
        <!-- Usage router link -->
        <router-link v-if="$route.name == 'Usage'" to="/usage" class="">
          <span class="">Χρήση</span>
        </router-link>
        <router-link v-else to="/usage" class="text-cyan-500 hover:underline">
          <span class="">Χρήση</span>
        </router-link>
        <span> | </span>
        <!-- FAQ router link -->
        <router-link v-if="$route.name == 'FAQ'" to="/faq" class="">
          <span class="">FAQ</span>
        </router-link>
        <router-link v-else to="/faq" class="text-cyan-500 hover:underline">
          <span class="">FAQ</span>
        </router-link>
        <span> | </span>
        <!-- Privacy router link -->
        <router-link v-if="$route.name == 'PrivacyPolicy'" to="/privacy-policy" class="">
          <span class="">Απόρρητο</span>
        </router-link>
        <router-link v-else to="/privacy-policy" class="text-cyan-500 hover:underline">
          <span class="">Απόρρητο</span>
        </router-link>
      </h3>
                
      <!-- Mobile Menu-->
      <!-- <div :class="isMenuOpen ? 'block' : ' hidden'"  @click="toggleMenu()" class="lg:hidden absolute top-4 right-20 rounded-xl border-2 border-white mx-auto bg-[#1B4D8E] text-center text-white text-xl gap-6 py-4 px-6">
        <router-link to="/">
          <div class="transition ease-in-out duration-300 underline-offset-2 hover:underline hover:-translate-y-1 hover:text-[#c33bc3]">Αρχική</div>
        </router-link>
        <router-link to="/gallery/all-pictures">
          <div class="transition ease-in-out duration-300 underline-offset-2 hover:underline hover:-translate-y-1 hover:text-[#c33bc3]">Portfolio</div>
        </router-link>
        <router-link to="/about-us/">
          <div class="transition ease-in-out duration-300 underline-offset-2 hover:underline hover:-translate-y-1 hover:text-[#c33bc3]">Ποιοι είμαστε</div>
        </router-link>
      </div> -->
    </nav>
  </div>
  
</template>

<script>
export default {
  name: 'NavBar3',
  data() {
    return {
      isMenuOpen: false
    //  current_category: ''
    }
  },
  methods: {
      toggleMenu() {
          this.isMenuOpen = !this.isMenuOpen
      }
  },
}
</script>
