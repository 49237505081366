<template>
  <!-- Copyright notice -->
  <div class="w-full">
    <footer class="flex flex-col justify-end items-end px-4 py-2 text-right text-gray-500 text-sm">
        <div>
          <a href="https://www.starfields.gr" rel="noreferrer noopener" target="_blank" class="flex flex-col lg:flex-row items-center lg:items-end">
            <span>Κατασκευή και υποστήριξη από την
              <span class="hover:text-white">StarFields</span>
            </span>
            <!-- <img :src="require('../assets/StarFields-Logo.png')" class="ml-1 h-7" alt="StarFields logo"/> -->
          </a>
        </div>
        <div class="flex flex-col sm:flex-row md:flex-row">
          <p class="pr-1">© 2023 starfields.gr</p>
          <p class="hidden sm:block pr-1">|</p>
          <p>Κατοχυρωμένα όλα τα δικαιώματα</p>
        </div>
        <a href="https://www.toptal.com/designers/subtlepatterns/random-grey-variations/" rel="noreferrer noopener" target="_blank">
          Η εικόνα φόντου ανακτήθηκε από το Subtle Patterns υπό το CC BY-SA 3.0
        </a>
    </footer>
  </div>
  
</template>

<script>

</script>
