import { createStore } from 'vuex'
import axios from 'axios'

export default createStore({
    state: {
        //media_domain: 'http://localhost:8000',
        //media_domain: 'http://vickys-corner.xyz',
        media_domain: 'https://x-svolis.gr',
        blog_startpage: '/blog/getting-started/introduction/',
        cookiesPopup: true,
        support_email: '',
        support_phone_number: '',
        message: '',
        type: '',
        toastToggleBool: false,
        toastKey: 0,
        categories: []
    },
    
    mutations: {
        toggleToast(state, args) {
            state.message = args['message']
            state.type = args['type']
            state.toastToggleBool = true
            state.toastKey += 1
            setTimeout(()=>
                (state.toastToggleBool = false), 3800)
        },
        initializeStore(state) {
          if (localStorage.getItem('cookiesPopup')) {
              state.cookiesPopup = (localStorage.getItem('cookiesPopup') == "false" ? false : true)
          } else {
              state.cookiesPopup = true
              localStorage.setItem('cookiesPopup', JSON.stringify(state.cookiesPopup))
          }
        },
        setIsLoading(state, status) {
          state.isLoading = status
        },
        saveCookiesPopupState(state) {
            localStorage.setItem('cookiesPopup', JSON.stringify(state.cookiesPopup))
        },
        setSupportEmail(state, contact_email){
            state.support_email = contact_email
        },
        setPhoneNumber(state, phone_number) {
            state.support_phone_number = phone_number
        },
        setDomainName(state, domain_name) {
            state.domain_name = domain_name
        },
        setCategories(state, categories){
            state.categories = categories
        },
    },
    actions: {
        async initializeStoreCheckToken({state, commit, dispatch}) {
            commit('initializeStore')
            dispatch('getSupportInfo')
            //dispatch('getCategories')
        },
        // NOTE support info section
        async getSupportInfo({state, commit}) {
            commit('setIsLoading', true)
            await axios
                .get('/api/v1/contact/support/')
                .then(response => {
                    commit('setSupportEmail', response.data.contact_email)
                    commit('setPhoneNumber', response.data.phone_number)
                    commit('setDomainName', response.data.domain_name)
                })
                .catch(error => {
                    console.log(error)
                    commit('toggleToast', {
                        'message': 'Κάτι πήγε στραβά. Παρακαλώ προσπαθήστε ξανά.',
                        'type': 'error'
                  })
                })
              
            commit('setIsLoading', false)
        },
    },
    
    getters: {
    },
    
    modules: {
    }
})
