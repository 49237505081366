<template>

  <!-- Toasts -->
  <div v-if="toastToggleBool" class="z-20 fixed right-1 sm:right-8 bottom-4">
      <div v-if="type==='success'" class="flex items-center p-4 mb-4 w-full max-w-xs text-green-700 border-2 border-green-700/80 bg-green-50 rounded-lg shadow-lg" role="alert">
          <div class="inline-flex flex-shrink-0 justify-center items-center w-8 h-8 text-green-600 rounded-lg">
              <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-check-circle-fill w-7" viewBox="0 0 16 16">
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
              </svg>
              <span class="sr-only">εικονίδιο V</span>
          </div>
          <div class="ml-3 mr-2 text-sm font-normal">{{ message }}</div>
          <button v-on:click="toastToggleBool=false" type="button" class="ml-auto -mx-1.5 -my-1.5 p-1 text-green-700 rounded-lg hover:text-green-400 focus:ring-2 focus:ring-green-300 inline-flex" data-dismiss-target="#toast-success" aria-label="κλείσιμο">
              <span class="sr-only">κλείσιμο</span>
              <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
          </button>
      </div>
      <div v-if="type==='error'" class="flex items-center p-4 w-full max-w-xs text-red-600 border-2 border-red-500 bg-red-100 rounded-lg shadow" role="alert">
          <div class="inline-flex flex-shrink-0 justify-center items-center w-8 h-8 text-red-500">
              <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-exclamation-octagon-fill w-7" viewBox="0 0 16 16">
                <path d="M11.46.146A.5.5 0 0 0 11.107 0H4.893a.5.5 0 0 0-.353.146L.146 4.54A.5.5 0 0 0 0 4.893v6.214a.5.5 0 0 0 .146.353l4.394 4.394a.5.5 0 0 0 .353.146h6.214a.5.5 0 0 0 .353-.146l4.394-4.394a.5.5 0 0 0 .146-.353V4.893a.5.5 0 0 0-.146-.353L11.46.146zM8 4c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995A.905.905 0 0 1 8 4zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
              </svg>
              <span class="sr-only">εικονίδιο X</span>
          </div>
          <div class="ml-3 mr-2 text-sm font-normal">{{ message }}</div>
          <button v-on:click="toastToggleBool=false" type="button" class="transform duration-100 ease-in-out ml-auto -mx-1.5 -my-1.5 text-red-500 hover:text-red-300 rounded-lg focus:ring-2 focus:ring-red-300 p-1 inline-flex h-7 w-7" data-dismiss-target="#toast-danger" aria-label="κλείσιμο">
              <span class="sr-only">κλείσιμο</span>
              <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
          </button>
      </div>
      <div v-if="type==='warning'" class="flex items-center p-4 w-full max-w-xs text-yellow-600 border-2 border-yellow-300 bg-yellow-100 rounded-lg shadow" role="alert">
          <div class="inline-flex flex-shrink-0 justify-center items-center w-8 h-8 text-yellow-600">
              <svg aria-hidden="true" class="w-8" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd"></path></svg>
              <span class="sr-only">εικονίδιο προσοχής</span>
          </div>
          <div class="ml-3 mr-2 text-sm font-normal">{{ message }}</div>
          <button v-on:click="toastToggleBool=false" type="button" class="ml-auto -mx-1.5 -my-1.5 text-yellow-600 hover:text-yellow-400 rounded-lg focus:ring-2 focus:ring-yellow-300 p-1 inline-flex" data-dismiss-target="#toast-warning" aria-label="κλείσιμο">
              <span class="sr-only">κλείσιμο</span>
              <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
          </button>
      </div>
  </div>
  
</template>

<script>
export default {
  name: 'Toasts',
  emits: [
    "toastToggleBool",
  ],
  props: {
    message: String,
    type: String,
    toastToggleBool: Boolean
  }
}
</script>
