<template>

  <section class="flex justify-center items-start bg-w-[4px]">
      <transition appear
        enter-active-class="duration-[2000ms] ease-out"
        enter-from-class="transition opacity-0"
        enter-to-class="opacity-100"
        leave-active-class="duration-[2000ms] ease-in"
        leave-from-class="opacity-100"
        leave-to-class="transition opacity-0"
      >
          <div class="max-w-[56rem] flex flex-col justify-center items-center sm:mx-2 mt-10 sm:mt-20 px-6 sm:px-14 py-14 sm:mx-6 border-2 border-gray-800 shadow-inner shadow-black/80 bg-gray-100 text-justify text-[#404650]">
              <h2 class="text-center mb-10">
                  Πολιτική Απορρήτου
              </h2>
              <!-- Navigation Bar -->
              <NavBar3/>
              
              <p class="text-center mb-4">
                Η πολιτική απορρήτου για αυτήν την υπηρεσία είναι σύντομη ώστε να μπορείτε να δείτε ότι είστε σε καλά χέρια.
              </p>
              <h3 class="text-center mb-4">
                Τα δεδομένα σας είναι απόρρητα
              </h3>
              <p class="mb-4">
                Είμαστε στην ευχάριστη θέση να σας ενημερώσουμε ότι αυτή η ιστοσελίδα δεν χρησιμοποιεί cookies ούτε συλλέγει/αποθηκεύει κανένα από τα ευαίσθητα προσωπικά σας δεδομένα ως μέρος αυτής της υπηρεσίας.
              </p>
              <p class="mb-4">
                Το Web Key Directory του <i>{{$store.state.domain_name}}</i> καταγράφει το αίτημά σας με χρονική σήμανση για σκοπούς συγκεντρωτικών αναλύσεων. Οποιοδήποτε τρίτο μέλος στον οποίο ανήκει ο διακομιστής Web Key Directory από τον οποίο ζητήσετε τα κλειδιά μέσω της αναζήτησής σας σε αυτή την ιστοσελίδα δεν βρίσκετε στον έλεγχό μας, επομένως ενδέχεται να καταγράψει κάποια δεδομένα. Ανεξάρτητα από αυτό, τα αιτήματα που υποβάλλονται για τις υπηρεσίες Web Key Directory δεν χρησιμοποιούμουν cookies ή JavaScript και ως εκ τούτου οι πληροφορίες που δίνονται είναι πολύ ελάχιστες σε σύγκριση με την επίσκεψη ενός κοινού ιστοτόπου.
              </p>
              <p class="mb-4">
                Λάβετε υπόψη ότι εάν επικοινωνήσετε μαζί μας, μας γνωστοποιούνται μόνο οι πληροφορίες που έχετε παρέχει. Για παράδειγμα, εάν μας στείλετε email, μοιράζεστε οικειοθελώς τη διεύθυνση ηλεκτρονικού ταχυδρομείου σας μαζί μας.
              </p>
                
              <p class="mb-4">
                Οποιεσδήποτε πληροφορίες ενδέχεται να διατηρούμε για εσάς λόγω της απευθείας επικοινωνίας σας μαζί μας θα παραμείνουν απόρρητες.
              </p>
                
              <p class="mb-4">
                Οι διευθύνσεις IP και οι πληροφορίες User Agent (περιηγητής, λειτουργικό σύστημα) του χρήστη από τις οποίες φαίνεται να προέρχονται οι επισκέπτες ενδέχεται να καταγράφονται για λόγους ασφαλείας και διάγνωσης του συστήματος. Αυτά τα δεδομένα μπορούν επίσης να χρησιμοποιηθούν σε συγκεντρωτική μορφή για την ανάλυση των τάσεων και της απόδοσης του ιστοτόπου.
              </p>
              
          </div>
      </transition>
  </section>
  
</template>

<script>
import NavBar3 from '@/components/NavBar3.vue'

export default {
  name: 'PrivacyPolicy',
  components: {
    NavBar3,
  },
  mounted() {
    window.scrollTo(0,0)
    document.title = ' Πολιτική Απορρήτου'
  }
}
</script>
